// path: frontend/equistab/src/components/FarmDesigner/HorseItem.js
import React from 'react';
import { useDrag } from 'react-dnd';
import { Paper, Typography } from '@mui/material';

const HorseItem = React.memo(({ horse }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'HORSE',
    item: { ...horse },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Paper
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: '1em',
        padding: '1em',
        cursor: 'move',
      }}
    >
      <Typography variant="subtitle1">{horse.name}</Typography>
      <Typography variant="body2">Age: {horse.age}</Typography>
    </Paper>
  );
});

export default HorseItem;
