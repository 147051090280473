import React from 'react';
import { useDrop } from 'react-dnd';
import { Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BoxItem = React.memo(({ box, handleHorseDrop, handleHorseRemove }) => {
    const [hovered, setHovered] = React.useState(false);
    const [{ isOver }, drop] = useDrop({
        accept: 'HORSE',
        drop: (horse) => handleHorseDrop(box.instanceId, horse),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} style={{ height: '100%' }}>
            <Paper
                elevation={2}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{
                    backgroundColor: isOver ? 'lightgreen' : 'white',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '1em',
                    boxSizing: 'border-box',
                    cursor: 'move',
                    willChange: 'transform',
                }}
            >
                <Typography variant="subtitle1">{box.name}</Typography>
                {box.horse ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2">Horse: {box.horse.name}</Typography>
                        <IconButton
                            size="small"
                            className="non-draggable"
                            onClick={(event) => {
                                event.stopPropagation();
                                handleHorseRemove(box.instanceId);
                            }}
                            style={{
                                marginLeft: '0.5em',
                                visibility: hovered ? 'visible' : 'hidden',
                            }}
                            aria-label="Remove horse from box"
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                ) : (
                    <Typography variant="body2">No horse assigned</Typography>
                )}
            </Paper>
        </div>
    );
});

export default BoxItem;
