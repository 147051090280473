// RoomCreationModal.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const RoomCreationModal = ({ isOpen, onClose, onCreateRoom }) => {
    const [roomName, setRoomName] = useState('');
    const [roomWidth, setRoomWidth] = useState(2);
    const [roomHeight, setRoomHeight] = useState(2);

    // Reset form when modal opens
    useEffect(() => {
        if (isOpen) {
            setRoomName('');
            setRoomWidth(2);
            setRoomHeight(2);
        }
    }, [isOpen]);

    const handleCreateRoom = useCallback(() => {
        if (roomName.trim() === '') return;

        onCreateRoom({
            id: `room-${Date.now()}`,
            name: roomName,
            width: Math.max(1, roomWidth),
            height: Math.max(1, roomHeight),
        });

        onClose();
    }, [roomName, roomWidth, roomHeight, onCreateRoom, onClose]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" component="h2">
                    Create a New Room
                </Typography>
                <TextField
                    fullWidth
                    label="Room Name"
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    type="number"
                    label="Width (Grid Units)"
                    value={roomWidth}
                    onChange={(e) => setRoomWidth(Number(e.target.value))}
                    margin="normal"
                    inputProps={{ min: 1 }}
                />
                <TextField
                    fullWidth
                    type="number"
                    label="Height (Grid Units)"
                    value={roomHeight}
                    onChange={(e) => setRoomHeight(Number(e.target.value))}
                    margin="normal"
                    inputProps={{ min: 1 }}
                />
                <Button
                    variant="contained"
                    onClick={handleCreateRoom}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    Create Room
                </Button>
            </Box>
        </Modal>
    );
};

export default RoomCreationModal;
