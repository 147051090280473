import React from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const RoomItem = React.memo(({ room, handleRoomRemove }) => {
    return (
        <Paper
            elevation={2}
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1em',
                boxSizing: 'border-box',
                position: 'relative',
                cursor: 'move',
            }}
        >
            <Typography variant="subtitle1">{room.name}</Typography>
            <IconButton
                size="small"
                className="non-draggable"
                onClick={(event) => {
                    event.stopPropagation();
                    handleRoomRemove(room.instanceId);
                }}
                style={{
                    position: 'absolute',
                    top: '0.25em',
                    right: '0.25em',
                }}
                aria-label="Remove room"
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Paper>
    );
});

export default RoomItem;
