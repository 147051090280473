// path: frontend/equistab/src/components/FarmDesigner/HorseList.js
import React from 'react';
import HorseItem from './HorseItem';
import { Typography } from '@mui/material';

const HorseList = React.memo(({ horses }) => {
  return (
    <div>
      <Typography variant="h6">Available Horses</Typography>
      {horses.map((horse) => (
        <HorseItem key={horse.id} horse={horse} />
      ))}
    </div>
  );
});

export default HorseList;
