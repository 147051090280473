// src/context/tokenUtils.js

import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { loginWithAzure } from '../services/authService';
const tenantURL = process.env.REACT_APP_AZURE_AD_B2C_TENANT_NAME;

export const renewToken = async (instance, login, loginPostInformation, navigate, retryCount = 3) => {
    const account = instance.getAllAccounts()[0];
    const silentRequest = {
        scopes: [`https://${tenantURL}.onmicrosoft.com/API-Scopes/Permission.All`],
        account: account
    };

    try {
        const response = await instance.acquireTokenSilent(silentRequest);
        await processTokenResponse(response, login, loginPostInformation, navigate);
    } catch (error) {
        console.error("Silent token acquisition failed", error);
        if (error instanceof InteractionRequiredAuthError) {
            try {
                const interactiveResponse = await instance.acquireTokenPopup(silentRequest);
                await processTokenResponse(interactiveResponse, login, loginPostInformation, navigate);
            } catch (interactiveError) {
                console.error("Interactive token acquisition failed", interactiveError);
                navigate('/auth');
            }
        } else if (retryCount > 0) {
            console.log(`Retrying silent token acquisition... attempts remaining: ${retryCount}`);
            setTimeout(() => renewToken(instance, login, loginPostInformation, navigate, retryCount - 1), 1000); // Retry after 1 second
        } else {
            navigate('/auth');
        }
    }
};

const processTokenResponse = async (response, login, loginPostInformation, navigate) => {
    const token = response.accessToken;
    login(token);
    const decodedToken = jwtDecode(response.idToken);
    const email = decodedToken.emails ? decodedToken.emails[0] : '';
    try {
        const userData = await loginWithAzure(email, response.account.homeAccountId, token);
        loginPostInformation(userData);
    } catch (error) {
        console.error('Failed to process token response', error);
        navigate('/auth');
    }
};

export const renewTokenIfNeeded = async (instance, token, login, loginPostInformation, navigate) => {
    if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeBuffer = 300;

        if (decodedToken.exp - currentTime < timeBuffer) {
            console.log("Token is about to expire, attempting to renew...");
            await renewToken(instance, login, loginPostInformation, navigate);
        }
    }
};
