// frontend/equistab/src/components/FarmDesigner/FarmLayout.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import GridLayout from 'react-grid-layout';
import RGL, { WidthProvider } from 'react-grid-layout';
import RoomCreationModal from './RoomCreationModal';
import { Button, Typography, Box } from '@mui/material';
import BoxItem from './BoxItem';
import RoomItem from './RoomItem';
import { fetchAllBoxTypes } from '../../services/BoxService';
import { useAuth } from '../../context/AuthContext';
import './FarmLayout.css';
import { fetchFarmLayout, saveFarmLayout } from '../../services/FarmLayoutService';

const ReactGridLayout = WidthProvider(RGL);

const FarmLayout = React.memo(
    ({ locationId, buildingId, onBoxTypesChange }) => {
        const [boxes, setBoxes] = useState([]);
        const [boxTypes, setBoxTypes] = useState([]); // Box types
        const [rooms, setRooms] = useState([]);
        const [layout, setLayout] = useState([]);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const { apiKey, organizationId, token: jwtToken } = useAuth();
        const [layoutId, setLayoutId] = useState(null);
        const [isInitializing, setIsInitializing] = useState(true); // Initialization flag

        // Track counts to trigger layout synchronization only on add/remove
        const [boxCount, setBoxCount] = useState(0);
        const [roomCount, setRoomCount] = useState(0);

        // Custom hook for loading box types
        const useLoadBoxTypes = (apiKey, organizationId, jwtToken, locationId, buildingId) => {
            useEffect(() => {
                const loadBoxTypes = async () => {
                    try {
                        const data = await fetchAllBoxTypes(apiKey, organizationId, jwtToken);
                        const validBoxTypes = data.filter(
                            (box) =>
                                box.locationId === locationId && box.buildingId === buildingId
                        );

                        setBoxTypes(validBoxTypes);
                    } catch (err) {
                        console.error('Error fetching box types', err);
                    }
                };
                if (locationId && buildingId) {
                    loadBoxTypes();
                }
            }, [apiKey, organizationId, jwtToken, locationId, buildingId]);
        };

        useLoadBoxTypes(apiKey, organizationId, jwtToken, locationId, buildingId);

        // Load existing layout
        useEffect(() => {
            const loadLayout = async () => {
                try {
                    const data = await fetchFarmLayout(apiKey, organizationId, jwtToken, locationId, buildingId);

                    if (data && data.layoutData.layout.length > 0) {
                        // Existing layout found
                        const loadedBoxes = data.layoutData.boxes || [];
                        const loadedRooms = data.layoutData.rooms || [];

                        // Set layout data from fetched data
                        setLayoutId(data.id);
                        setLayout(data.layoutData.layout);

                        // Restore boxes and rooms
                        setBoxes(loadedBoxes);
                        setRooms(loadedRooms);

                        // Update counts
                        setBoxCount(loadedBoxes.length);
                        setRoomCount(loadedRooms.length);
                    } else {
                        // Initialize layout if none exists
                        if (boxTypes.length === 0) {
                            console.warn('No layout data found and box types are not loaded yet.');
                            return;
                        }

                        const initialBoxes = boxTypes.flatMap((box) =>
                            Array.from({ length: Number(box.amount) }, (_, index) => ({
                                ...box,
                                instanceId: `${box.id}-${index}`,
                                type: 'box',
                                horse: null, // Initialize horse as null
                            }))
                        );

                        const initialLayout = initialBoxes.map((box, index) => ({
                            i: box.instanceId,
                            x: (index % 4) * 3,
                            y: Math.floor(index / 4) * 2,
                            w: box.width || 1,
                            h: box.height || box.length || 1,
                            static: false,
                        }));

                        const newLayoutData = {
                            layout: initialLayout,
                            boxes: initialBoxes,
                            rooms: rooms, // Initialize rooms if needed
                        };

                        // Save the newly created layout
                        const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, {
                            layoutData: newLayoutData,
                            locationId,
                            buildingId,
                        });

                        setLayoutId(savedLayout.id); // Update layout ID after creation
                        setLayout(initialLayout);
                        setBoxes(initialBoxes);

                        // Update counts
                        setBoxCount(initialBoxes.length);
                        // Rooms are already initialized
                    }
                } catch (error) {
                    console.error('Error loading farm layout', error);
                } finally {
                    setIsInitializing(false); // Initialization complete
                }
            };

            if (locationId && buildingId) {
                loadLayout();
            }
        }, [locationId, buildingId, apiKey, organizationId, jwtToken, boxTypes]);

        // Save layout to backend
        const saveLayout = useCallback(
            async (dataToSave) => { // Accept dataToSave as a parameter
                try {
                    if (!dataToSave.layoutData || dataToSave.layoutData.layout.length === 0) {
                        console.error('No layout data to save');
                        return;
                    }

                    // Call the save API, whether it's a new layout or an update
                    const savedLayout = await saveFarmLayout(apiKey, organizationId, jwtToken, dataToSave);

                    // If a new layout was created, store the new layoutId
                    if (!layoutId && savedLayout.id) {
                        setLayoutId(savedLayout.id);
                    }

                    console.log('Layout saved successfully');
                } catch (error) {
                    console.error('Error saving farm layout', error);
                }
            },
            [layoutId, apiKey, organizationId, jwtToken, locationId, buildingId]
        );

        // Filtered items (boxes and rooms)
        const filteredItems = useMemo(() => {
            const validBoxes = boxes.filter(
                (box) => box.locationId === locationId && box.buildingId === buildingId
            );
            const validRooms = rooms.filter(
                (room) => room.locationId === locationId && room.buildingId === buildingId
            );
            return [...validBoxes, ...validRooms];
        }, [boxes, rooms, locationId, buildingId]);

        // Update layout when boxes or rooms are added/removed
        useEffect(() => {
            const newLayout = filteredItems.map((item, index) => {
                const existingLayoutItem = layout.find((l) => l.i === item.instanceId);
                if (existingLayoutItem) {
                    return existingLayoutItem;
                } else {
                    return {
                        i: item.instanceId,
                        x: (index % 4) * 3,
                        y: Math.floor(index / 4) * 2,
                        w: item.width || 1,
                        h: item.height || item.length || 1,
                        static: false,
                    };
                }
            });
            setLayout(newLayout);
            const validBoxes = boxes.filter(
                (box) => box.locationId === locationId && box.buildingId === buildingId
            );
            onBoxTypesChange(validBoxes);
        }, [filteredItems, boxCount, roomCount, locationId, buildingId, onBoxTypesChange]);

        // Handlers
        const onLayoutChange = useCallback(
            async (newLayout) => { // Make the function async
                setLayout(newLayout);

                const updatedLayoutData = {
                    layout: newLayout,
                    boxes: boxes,
                    rooms: rooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleHorseDrop = useCallback(
            async (instanceId, horse) => { // Make the function async
                // Compute updated boxes
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse } : box
                );

                // Update state
                setBoxes(updatedBoxes);

                // Compute the new layoutData
                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };

                // Save the updated layoutData
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const openRoomCreationModal = useCallback(() => {
            setIsModalOpen(true);
        }, []);

        const closeRoomCreationModal = useCallback(() => {
            setIsModalOpen(false);
        }, []);

        const handleCreateRoom = useCallback(
            async (newRoom) => { // Make the function async
                const roomWithLocation = {
                    ...newRoom,
                    amount: '1',
                    autoGenerateInvoice: false,
                    locationId,
                    buildingId,
                    instanceId: `room-${Date.now()}`,
                    id: `room-${Date.now()}`,
                    type: 'room',
                };
                const updatedRooms = [...rooms, roomWithLocation];
                setRooms(updatedRooms);
                setRoomCount((prevCount) => prevCount + 1);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [locationId, buildingId, rooms, boxes, layout, saveLayout, layoutId]
        );

        const handleHorseRemove = useCallback(
            async (instanceId) => { // Make the function async
                // Compute updated boxes
                const updatedBoxes = boxes.map((box) =>
                    box.instanceId === instanceId ? { ...box, horse: null } : box
                );

                // Update state
                setBoxes(updatedBoxes);

                // Compute the new layoutData
                const updatedLayoutData = {
                    layout: layout,
                    boxes: updatedBoxes,
                    rooms: rooms,
                };

                // Save the updated layoutData
                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [boxes, layout, rooms, saveLayout, layoutId, locationId, buildingId]
        );

        const handleRoomRemove = useCallback(
            async (instanceId) => { // Make the function async
                const updatedRooms = rooms.filter((room) => room.instanceId !== instanceId);
                setRooms(updatedRooms);
                setRoomCount((prevCount) => prevCount - 1);

                const updatedLayoutData = {
                    layout: layout,
                    boxes: boxes,
                    rooms: updatedRooms,
                };

                await saveLayout({
                    id: layoutId,
                    layoutData: updatedLayoutData,
                    locationId,
                    buildingId,
                });
            },
            [rooms, boxes, layout, saveLayout, layoutId, locationId, buildingId]
        );

        return (
            <>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <Typography variant="h5" gutterBottom>
                        Design the Layout
                    </Typography>
                    <Button variant="contained" onClick={openRoomCreationModal}>
                        Create New Room
                    </Button>
                </Box>
                <div
                    id="grid-container"
                    style={{
                        backgroundColor: '#f9f9f9',
                        position: 'relative',
                        boxSizing: 'border-box',
                        userSelect: 'none',
                    }}
                >
                    {!isInitializing && layout.length > 0 && (
                        <ReactGridLayout
                            className="layout"
                            layout={layout}
                            cols={12}
                            rowHeight={30}
                            width={1200}
                            onLayoutChange={onLayoutChange}
                            useCSSTransforms={true}
                            isBounded={false}
                            draggableCancel=".non-draggable"
                        >
                            {filteredItems.map((item) => (
                                <div key={item.instanceId} data-grid={layout.find((l) => l.i === item.instanceId)}>
                                    {item.type === 'box' ? (
                                        <BoxItem
                                            box={item}
                                            handleHorseDrop={handleHorseDrop}
                                            handleHorseRemove={handleHorseRemove}
                                        />
                                    ) : (
                                        <RoomItem room={item} handleRoomRemove={handleRoomRemove} />
                                    )}
                                </div>
                            ))}
                        </ReactGridLayout>
                    )}
                </div>
                <RoomCreationModal
                    isOpen={isModalOpen}
                    onClose={closeRoomCreationModal}
                    onCreateRoom={handleCreateRoom}
                />
            </>
        );
    }
);

export default FarmLayout;
