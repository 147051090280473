// path: frontend/equistab/src/components/FarmDesigner/FarmDesigner.js
import React, { useState, useEffect, useCallback } from 'react';
import {
    Box,
    Grid,
    MenuItem,
    Select,
    Typography,
    Paper,
    IconButton,
    Collapse,
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FarmLayout from './FarmLayout';
import HorseList from './HorseList';
import { getAllHorses } from '../../services/horseService';
import { fetchLocationSettings } from '../../services/LocationSettingsService';
import { useAuth } from '../../context/AuthContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

const FarmDesigner = () => {
    const [horses, setHorses] = useState([]);
    const [filteredHorses, setFilteredHorses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [isSelectionOpen, setIsSelectionOpen] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const { apiKey, organizationId, token: jwtToken } = useAuth();

    // Fetch horses and locations
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [horsesData, locationsData] = await Promise.all([
                    getAllHorses(apiKey, organizationId, jwtToken),
                    fetchLocationSettings(apiKey, organizationId, jwtToken),
                ]);
                setHorses(horsesData);
                setLocations(locationsData);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
    }, [apiKey, organizationId, jwtToken]);

    // Handle location change
    const handleLocationChange = useCallback(
        (event) => {
            const locationId = event.target.value;
            setSelectedLocation(locationId);

            const selectedLocationData = locations.find(
                (location) => location.id === locationId
            );
            setBuildings(selectedLocationData?.buildings || []);
            setSelectedBuilding('');
        },
        [locations]
    );

    // Handle building change
    const handleBuildingChange = useCallback((event) => {
        setSelectedBuilding(event.target.value);
        setIsSelectionOpen(false);
    }, []);

    // Toggle selection visibility
    const toggleSelection = useCallback(() => {
        setIsSelectionOpen((prev) => !prev);
    }, []);

    // Toggle fullscreen mode
    const toggleFullScreen = useCallback(() => {
        setIsFullScreen((prev) => !prev);
    }, []);

    // Update filtered horses when boxes change
    const handleBoxTypesChange = useCallback(
        (boxTypes) => {
            const boxTypeIds = boxTypes.map((box) => box.id);
            const assignedHorseIds = boxTypes
                .filter((box) => box.horse)
                .map((box) => box.horse.id);

            const filtered = horses.filter(
                (horse) =>
                    boxTypeIds.includes(horse.boxTypeId) &&
                    !assignedHorseIds.includes(horse.id)
            );
            setFilteredHorses(filtered);
        },
        [horses]
    );

    return (
        <DndProvider backend={HTML5Backend}>
            <Box
                sx={{
                    padding: 2,
                    position: isFullScreen ? 'fixed' : 'relative',
                    top: 0,
                    left: 0,
                    width: isFullScreen ? '100vw' : 'auto',
                    height: isFullScreen ? '100vh' : 'auto',
                    zIndex: isFullScreen ? 1300 : 'auto',
                    backgroundColor: isFullScreen ? '#fff' : 'inherit',
                    overflow: isFullScreen ? 'auto' : 'visible',
                }}
            >
                <Paper elevation={3} sx={{ padding: 3, marginBottom: 2 }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" gutterBottom>
                            Select a Location and Building
                        </Typography>
                        <Box>
                            <IconButton onClick={toggleSelection}>
                                <ExpandMoreIcon />
                            </IconButton>
                            <IconButton onClick={toggleFullScreen}>
                                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                            </IconButton>
                        </Box>
                    </Box>

                    <Collapse in={isSelectionOpen}>
                        <Select
                            value={selectedLocation}
                            onChange={handleLocationChange}
                            displayEmpty
                            fullWidth
                            sx={{ marginBottom: 2 }}
                        >
                            <MenuItem value="" disabled>
                                Select a Location
                            </MenuItem>
                            {locations.map((location) => (
                                <MenuItem key={location.id} value={location.id}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>

                        {selectedLocation && (
                            <Select
                                value={selectedBuilding}
                                onChange={handleBuildingChange}
                                displayEmpty
                                fullWidth
                                sx={{ marginBottom: 2 }}
                            >
                                <MenuItem value="" disabled>
                                    Select a Building
                                </MenuItem>
                                {buildings.map((building) => (
                                    <MenuItem key={building.id} value={building.id}>
                                        {building.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </Collapse>
                </Paper>

                {selectedBuilding ? (
                    <Grid
                        container
                        spacing={2}
                        sx={{ height: isFullScreen ? 'calc(100vh - 200px)' : 'auto' }}
                    >
                        <Grid item xs={2}>
                            <HorseList horses={filteredHorses} />
                        </Grid>
                        <Grid item xs={10}>
                            <Paper elevation={3} sx={{ padding: 2 }}>
                                <FarmLayout
                                    locationId={selectedLocation}
                                    buildingId={selectedBuilding}
                                    horses={filteredHorses}
                                    onBoxTypesChange={handleBoxTypesChange}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography variant="body1">
                        Please select a building to start designing the structure.
                    </Typography>
                )}
            </Box>
        </DndProvider>
    );
};

export default FarmDesigner;
